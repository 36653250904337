 
.screen_analytics h2{
    font-size : 0.5em; 
}

.screen_analytics h3{
    color:#043d58;  
}

.screen_analytics h4{
    color:#043d58;  
    font-size:1em;
    text-align: center;
}


div .analytics-title {
    background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
    border-bottom:  3px solid #0076ad;
    color: white;
 
    text-align: center;
    margin: 0.3em;

    padding:3px;
    vertical-align: middle;
 }

.background{
    background-color: #ededed;
}

.graph{
    border : 1px solid #e0e0e0;
    margin:1em;
    padding:1em;
    background-color:#f7f7f7;
    box-shadow: 1px 1px 1px 1px #ccc; 
}



.showcase_number{
    color : #525252;
    font-size:1.5em;
    margin:auto;
    text-align: center;
    background-color:#f7f7f7;
    border : 1px solid #e0e0e0;
    box-shadow: 1px 1px 1px 1px #ccc; 
    color:#003457;
    margin-top:1em;
}

 .analytics-filters{

   
    background-color:white;
    box-shadow: 1px 1px 1px 1px #ccc; 
    margin-bottom:10px
    
 }

 div.analytics-filters .MuiSlider-root{
 max-width: 400px;
 margin:0;
 }

div.analytics-filters .col-1, div.analytics-filters .col-2{
     padding-left:1em;
     padding-right:1em;
     

 }
 div.analytics-filters .last{
     border-right:none
 }

 .barchart-tooltip{
     color:red
 }

 div.screen_analytics .table td
 ,  div.screen_analytics .table th
  {
     font-size : 0.86em
 }

 div .analytics-section-3{
    border-left : 1px solid lightgrey
   
 }

 div.screen_analytics .row{
margin : 0;
 }

 div .barchart-label{
     color:red
 }

 div.screen_analytics .table-striped tbody tr:nth-of-type(odd) {
    
 }