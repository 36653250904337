div .card_chat_message{
  background-color: #f5f5f5;
  padding:0;
  box-shadow: 0 2px 2px lightgrey;
  border-radius: 10px;
   
} 
div .card_chat_message .MuiTypography-h5, div .card_chat_message .MuiTypography-body1{
  font-size: 0.9em;
}
div .MuiTypography-body2{
  color:#595959;
}
div .header_chat_message{
  padding:0.5em;
  font-size: 1em;
}
div .content_chat_message{
  padding:0.5em;
  font-size: 1em;
}
div .card_chat_message.quantum{
  background-color: #aec7d4;
 
} 
  
 .agent-suggestion{
  padding : 0.7em;
  font-size: 0.6em;
  opacity : 0.85;

}

.table-print td{
border : solid 1px grey;
color : black;
font-size: 0.55em;
padding-top : 0em;
padding-bottom : 0em;
padding-left : 0.2em;
padding-right:0;
width:5em;

line-height:auto;

}
.table-print td.scalable{
  line-height:auto,
}
.table-print .header{
 background-color : #003457;
 color : white;
 font-size : 0.75em;
}

.table-print .header-green{
  background-color : #2d4622;
  color : white;
  font-size : 0.75em;
 }
 
 .table-print .label-green{
  background-color : #aab272;

 }


.table-print .label{
 background-color : #bfbfbf;
 padding:0;
 padding-left:0.1;
 padding-right:0.2;
}
.table-print .no-border-2{
  border:hidden
}
.table-print .no-border{
  border:none

 }
 .table-print .transparent{
  opacity:0;
  color:white;
  
 }

 .table-print td.no-border-left{
  border-left:none;
  padding : 0;
  padding-right : 0.2em
 }
 .table-print .no-border-right{
  border-right:none
 }


.table-print .important{
  font-weight: bold;
}

.table-print td.small{
  font-size : 0.7em
}

#print-logo{
  position: absolute;
  
  margin : auto;
  margin-top : 50px;
  right: 10px;
  z-index : 30
}
 
table.table-adhoc th{
  padding : 0;
  padding-left : 2px;
  text-align:left;
}

table.table-adhoc td, table.table-adhoc th{
  font-size : 0.7em
}
table.table-adhoc td{
  padding : 0.2em
}

.row-header{
  padding-left : 1em;
  padding-right : 1em;

  }
  
  .row-header .copy-prepend{
    cursor: pointer;
  }
  .row-header td{
   padding:0;
   padding-right:0.5em;
   font-size : 0.8em
   
  }


  .small-icon{
    max-height : 30px;
  }


  .shipment-column{
    z-index : 2
  }

  .pricing-column{
    z-index : 0
  }

  .dimension_modal_header{
    background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
    border-bottom:  3px solid #0076ad;
    color: white;
 }

 .modal_header{
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
  border-bottom:  3px solid #0076ad;
  color: white;
}

 

  .table thead th, .table tbody td{
    text-align : left;
    vertical-align:  middle;
  }
  
  .table tbody td.align-right{
    text-align:right;
  }

  .modal {
    width:100%
  }
  div .modal-print {
    max-width:600px;
    margin-left:10%;
    z-index:10;
  }
  div .modal-xl{
    max-width:90%;
    margin-left:5%;
  }

  div .modal-m{
    max-width:70%;
    margin-left:15%;
  }


  div .modal-attachment{
    max-width:50%;
    margin-left:20%;
    margin-right:20%;
  }
  
  
  div .modal-content {
    width:100%
  }

  .modal-remark div .modal-content{
    width:300%
  }
  
#selling_table_container{
  overflow : auto;
  max-height : 20em;
  font-size : 0.7em
}

 

div.card.col{
  margin : 1em;
  
}

div.col{
  margin-top:1.3em
}



.shipment-column, .pricing-column{
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
   
}


div.row.section-routing, 
div.card.col.section-routing,
div.row.section-margin,
div.card.col.section-margin{
  margin-top : 0
}

div.row.section-remark{
margin : 0em
}

div.section-remark .card.col{
margin:0
}



div.important-input label{
  font-weight : bold;
  color: #6b6b6b;
  
}

div.important-input input,
div.important-input .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child{
 
  opacity:1;
  font-weight : bold;
  font-family: inherit;
  background-color:white;
  border: 2px solid rgb(4, 61, 88,0.6) ;
  border-radius:0.25rem;
  font-family : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
 
}
div.is-invalid input, 
div.is-invalid .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  border: 2px solid red ;
}

div.is-warning input, 
div.is-warning .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  border: 2px solid orange ;
}

div.important-input input{
  color : #6b6b6b
}
div.important-input .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child{
  color: rgb(0,0,0,1)

}



div.important-input .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child{
  padding:0;
  padding-top:0.2em;
  padding-bottom:0.2em;
  
}

 
.invalid, div.important-input label.invalid{
  color:red;
  font-size:0.65em;
  font-weight:normal;
}

.warning, div.important-input label.warning{
  color:orange;
}

div.section-margin  div.col-1.margin-label
,  div.section-margin  div.col-1.margin-label-edit  {
  vertical-align: bottom;
  text-align: right;
  padding-top:1.5em;

 
}
div.section-margin .important input{
  border : 1px solid ;
}

div.section-margin .important label{
 
  font-weight:bold;
}

div.col-1.margin-label-edit{
  color:#0076ad
 
}

.label-edit{
  color:#0076ad
}


.section-margin .col-1{
  flex: 0 0 10%;
  max-width: 10%;
}

div input.margin-edit{
  border: #a1d8ff solid 1px;
  color:grey;
  background-color:white;
}

div .margin-light{
  
  color : #c2c2c2;
}

.section-margin .hidden-label{
  color:white
}


.section-margin div.col-1{
  padding:0;
  margin:0;
  margin-left:0.2em;
  margin-right:0.2em;

vertical-align: middle;
height:2.2em;
}
.section-margin input{
  background-color:#e6e6e6;
}

 

.section-margin label{
  margin:0
}

h5{
  display:inline;
  margin-right:1em;
}

.shipment-section div.col{
 
margin:0;
}


div .margin-row{
 margin-bottom:-2em;
}

 div .buy-row, div .sell-row {
  margin-top:1em;
  margin-bottom:0;
}
div .sell-edit-row, div .buy-edit-row{
  margin-top:0;
  margin-bottom:1em;
}

div .margin-perkg .form-control, div .margin-total .form-control{
  border: 1px solid black;
}

label.margin-info{
  color: grey;
  font-style:italic;
  font-size:0.7em;
}

.detailed_info{
  margin-left: 1em;
}

body canvas{
  z-index: 10000;
}

button{
  max-height:5em;
}

.modal-remark{
  color:red;
}
div.col button.centered{
  margin-top:50%
}

 
div .MuiTypography-body1{
  font-size: 1em;
}

.table-print td.text-conditions{
  font-size: 0.60em;
}

.table.table-print{
  margin-bottom:0.4rem
}

#modalPrint div.col{
  margin-top:0.8em;
}
#modalPrint{
  max-width: 600px;
}

div .table-adhoc thead th {
vertical-align: middle;
font-size : 0.7em
}

.option {
  margin-bottom:3px;
}
 

.routing:hover{
  background : #2b5876 ;
  color:white!important;
}

.routing_station :hover{
  background :white!important ;
  color:#2b5876;
  cursor: none;
}

.carrier_routings{
  border-bottom: 1px solid lightgray;
}
 
.schedule-flight:hover{
  cursor: pointer;
  border:3px solid #e06b16!important;

}
 
.schedule-segment-date{
  width:14%;
}

.cancelled-flight{
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>");
  background-repeat:no-repeat;
  background-position:center center;
  background-size: 100% 100%, auto;
  pointer-events:none

}

.blocked-flight{
  pointer-events:none;
  background-repeat:no-repeat;
  background-position:center center;
  background-size: 100% 100%, auto;
}

.closed-flight{
  pointer-events:none;
  background-repeat:no-repeat;
  background-position:center center;
  background-size: 100% 100%, auto;
}

.to_rotate_90{
    position: relative;
    right: 6.5em;
    top: 3em;
    height: 0em;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    color: black;
}

.input-expected-time{
  display: inline-block;
  width: 35px;
  text-align: right;
  margin-left: 5px;
}

.input-expected-time::-webkit-outer-spin-button,
.input-expected-time::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.label-expected-time{
  margin-left: 5px;
}

.days-expected{
  color: red;
  white-space: nowrap;
}

.carrier_routings_checkbox {
  float: right;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1150px; 
  height: 300px; 
  margin: auto; 
  text-align: center;
}

.dropzone > section {
  height: 100%;
}

.dropzone > section > div {
  height: 100%;
  border: 2px dashed #cbd5e1;
  border-radius: 1rem;
  background-color: #f8fafc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.dropzone > section > div:hover {
  cursor: pointer;
}

.dropzone > section > div > p {
  margin: 0;
}

.dropzone > section > div > p > u {
  text-decoration: underline;
}

.dropzone > section > div.drag-active {
  background-color: #ffffff;
}

.dropzone > section > #drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0;
  left: 0;
  opacity: 0.5;
}


