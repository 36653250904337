.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

h1,h2,h3,h4{
  color:#003457;
}
h1,h2,h3,h4,h5{
  font-weight: 600;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

button, input{
   margin : 0;
   padding : 0em
  
}

label{
  font-size:0.76em
}
 
 


 .col{
   text-align: bottom
 }

 .small-table th,
 .small-table td{
  padding: 0.3rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
 
 }

 .dropdown{
   padding : 0px;
   
 }

 buttton{
   margin-left : 0.2em
 }
button a,button a:hover {
  color :white;
}

.modal-content{
  width : 150%;
}

.MuiSlider-track, .MuiSlider-thumb{
  color:#003457;
}
 .MuiSlider-valueLabel{
   opacity : 0.85;
 }
span[class^="PrivateValueLabel-label"] {
  font-size : 0.8em;
  color : black;
}


.row-filter{
  margin-bottom: 1em;
}

span[class^="PrivateValueLabel-circle"]{
  color : transparent;
  margin:1.3em

}
span[class^="PrivateValueLabel-label"]{
 color : black;
font-size : 1.2em;
}

span[class^="PrivateValueLabel-circle"]{
  transform: scale(1) translateX(0px) translateY(10) rotate(0);

}

 
button.edit_menu{
  height : 3em;
 
 
}

.btn-secondary{
  background-color:#57618c;
  border:none
}

.btn{
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset!important;
}

button{
  margin : 0.1em
}

div.edit_menu{
  text-align : right
}


 div .form-control{
   padding:0
 }
div .MuiInputBase-root  {
  font-size : 1em;
  padding : 0;
  margin : 0;
  opacity:1;
  color:black;
}

 div 	.MuiAutocomplete-option{
 font-size : 0.7em
}

div .MuiAutocomplete-endAdornment{
  font-size : 0.1em;
  margin : 0;
  padding : 0;
}
 
.col-button button{
background-color : red;
max-height:2em
}


.nav a, .nav a:hover{
  color:white
}

.nav, html{
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
  background-size: 200% auto;
}

div .btn-primary {
  background-color: #055a82;
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376  51%, #2b5876  100%);
  background-size: 200% auto;
  border-color:transparent;
  box-shadow: 0 0 20px #eee;
  font-weight: 600;
}
div .btn-primary:hover{
  background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%);
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  font-weight: 700;
  transition: 0.s;
  background-position: right center;

}
div .btn-success {
  background-color: #1e8756;
  background-image: linear-gradient(to right, #3CA55C 0%, #9cb549  51%, #3CA55C  100%);
 
  background-size: 200% auto;
  border-color:transparent;
  box-shadow: 0 0 20px #eee;
  font-weight: 600;
}
div .btn-success:hover, div .btn-success-special{
  border-color:transparent;
  background-image: linear-gradient(to right, #80cf98 0%, #bdd470  51%, #80cf98  100%);
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  font-weight: 700;
   
}
div .btn-danger {
  background-color: #a62b3d;
  background-image: linear-gradient(to right, #d53369 0%, #c97255  51%, #d53369  100%);
  background-size: 200% auto;
  border-color:transparent;
  box-shadow: 0 0 20px #eee;
  font-weight: 600;
}
div .btn-danger:hover, div .btn-danger-special{
  border-color:transparent;
  background-image: linear-gradient(to right, #DD5E89 0%, #F7BB97  51%, #DD5E89  100%);
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  font-weight: 700;
 
}






div .btn-primary:hover{
  background-color:#0870a1;
  border-color:transparent
}


div .btn-secondary{
  background-color:#e6e6e6;
  color:black
}
div .btn-secondary:hover{
  background-color:#bfbfbf
}

h5,h6{
  color:#043d58;
}


  body {
  font-family : Helvetica;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.route_col .col{
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;

  margin-top:0.2em;
 }

 .clickable{
   cursor: pointer;
 }
