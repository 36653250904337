div  .users-list   th, div .users-list   td{
  
  padding:0.5em;
  text-align : center;
  font-size:1em;
  vertical-align: middle;
 text-align:center;
 
}

.error{
  color: red;

}

div.is-invalid input, 
div.is-invalid .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  border: 2px solid red ;
}

div  .users-list .extra-large{
  width:20em;
  padding:0;
}
 

.filter-menu, .page-menu{
  background-color:  white;
}
 
 
.filter-menu{
 
  border-bottom:2px solid lightgrey;
  padding-left:1em;
  padding-top:0.5em;
}

.fixed-menu{
  position: fixed;
  top: 0;
  width: 100%;
  z-index:100;

  border-bottom:2px solid lightgrey;
  padding-left:1em;
  padding-top:0.5em;
}

 

div.users-list {
  height: 75vh;
}
div  .users-list table {
  display: flex;
  flex-flow: column;
  height: 90%;
  width: 100%;
}
div .prices-list table{
 
  display: flex;
  flex-flow: column;
  height: 90%;
  width: 100%;

}
div .prices-list table td, div .prices-list table th {
  font-size: 70%;
}
div  .users-list table thead {
  /* head takes the height it requires, 
  and it's not scaled when table is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
  border-bottom:  3px solid #0076ad;
  color: white;
}

.user_modal_header {
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
  border-bottom:  3px solid #0076ad;
  color: white;
}

div  .users-list table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
}
div  .users-list table tbody tr {
  width: 100%;
}
div  .users-list table thead,  div  .users-list table tbody tr {
  display: table;
  table-layout: fixed;
}


