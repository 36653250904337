li a.current_button{
  background-color:#0076ad;
  font-weight:bold;
}

div div.main-nav {
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
   
  margin:0;
  border-bottom:  3px solid #0076ad;
}


 .main-nav a{
 background: none;
 font-weight: 600;
 }
 .main-nav .nav-item{
  background: none;
 }
div .nav-tabs  {
 border:none
}

.badge-purple{
  background-color: #a83283
}

.main-nav .station-info{
  color:white;
  text-align:right;
  position : absolute;
  right:2em;
}
.main-nav .station-info li{
  vertical-align: middle;
}