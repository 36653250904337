.row-filter{
     background-color: white;
}

div button.centered {
     margin-top: 2em;
     width:3em;
 }
div textarea.form-control{
     height:10em;
}

div textarea#gha_text{
  height:5em;
}

 .section-title h4, .section-title span{
      display:inline-block;
 }

 .settings-config h2{
     color: #003457;

 }
.settings-config h4{
     color: #003457;
     font-size: 1.4em
 }
.settings-config h5{
     font-size: 0.8em
 }

 .settings_portofolio_panel{
     background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
     border-bottom:  3px solid #0076ad;
     color: white;
 }

 .settings_modal_header{
    background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
    border-bottom:  3px solid #0076ad;
    color: white;
 }

 .uld_pricing_panel{
     background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
     border-bottom:  3px solid #0076ad;
     color: white;
 }


  .editable-content {
    height: 150px; 
    overflow-y: auto;
    border: 1px solid #ccc; 
    padding: 8px; 
  }

  
  .bold-active {
    background-color: #A7B3B5; 
  }

  .highlighted-active {
    background-color: #A7B3B5; 
  }

/* Remove Arrows/Spinners from the input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input #booking_validity_input {
  width: 10%;
}